export const COMMON_EVENTS = {
  FETCH_EVENT_GROUP_SUCCESS: 'FETCH_EVENT_GROUP_SUCCESS',
  FETCH_EVENT_GROUP_FAILURE: 'FETCH_EVENT_GROUP_FAILURE',
  FETCH_STUDENT_SUCCESS: 'FETCH_STUDENT_SUCCESS',
  FETCH_STUDENT_FAILURE: 'FETCH_STUDENT_FAILURE',
};

export const STOPS_EVENTS = {
  STOPS_LIST_FETCH_FAILURE: 'STOPS_LIST_FETCH_FAILURE',
  STOP_FETCH_FAILURE: 'STOP_FETCH_FAILURE',
  SAVE_SUCCESS: 'SAVE_SUCCESS',
  STOP_DELETE_FAILURE: 'STOP_DELETE_FAILURE',
  STOP_DELETE_SUCCESS: 'STOP_DELETE_SUCCESS',
  FETCH_LIST: 'FETCH_LIST',
  REFRESH: 'REFRESH',
};

export const CAMPUS_EVENTS = {
  CAMPUS_LIST_FETCH_FAILURE: 'CAMPUS_LIST_FETCH_FAILURE',
  SAVE_SUCCESS: 'SAVE_SUCCESS',
};

export const ROUTES_EVENTS = {
  ROUTE_FETCH_FAILURE: 'ROUTE_FETCH_FAILURE',
};

export const STUDENTS_EVENTS = {
  STUDENTS_PDF_GENERATION_FAILURE: 'STUDENTS_PDF_GENERATION_FAILURE',
  STUDENTS_PDF_GENERATION_SUCCESS: 'STUDENTS_PDF_GENERATION_SUCCESS',
  FETCH_LIST: 'FETCH_LIST',
  REFRESH: 'REFRESH',
};

export const TRANSPORT_EVENTS = {
  FETCH_STOPS_ARRIVAL_TIMES: 'FETCH_STOPS_ARRIVAL_TIMES',
  FAILURE_FETCH_STOPS_ARRIVAL_TIMES: 'FAILURE_FETCH_STOPS_ARRIVAL_TIMES',
};
