//Lib Imports
import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { Box, Text, TextArea, Button, CheckBox, Grid, Accordion, Anchor } from 'grommet';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

//Components Imports
import FormField from 'granite-admin/core/components/FormField';
import Select from 'granite-admin/core/components/Select';
import { useToast } from 'granite-admin/core/components/Toast';
// import DatePicker from 'granite-admin/core/components/DatePicker';

//Application Imports
import DatePicker from 'common/DatePicker';
import ConfirmLayer from 'common/TopNavbar/components/ConfirmLayer';
import { viewDateFormat, getFormatedDate } from 'common/CommonFunction';
import { fetchTransportData, getRouteSmsBalance, messageAllRoutes } from 'routeMessage/controllers/routeMessage';
import { ROUTE_MESSAGE_EVENTS } from 'routeMessage/controllers/events';
import {
  StyledAccordionPanel,
  commonStyle,
  recipientsColor,
  availableMsgColor,
  StyledHeading,
  StyledText,
} from 'common/TopNavbar/styledComponents/elements';
import {
  calculatMessageByteSize,
  onRoutesCheckAll,
  onCheckAll,
  checkedState,
  onCheck,
  parseRouteList,
  validationSchema,
} from 'common/TopNavbar/helpers/routeMessageHelper';
import {
  byteSize,
  totalBtyeSize,
  childrenBoardedOptions,
  messageTypeOptions,
} from 'common/TopNavbar/helpers/constants';
import { ERROR_MESSAGES, MESSAGE, LINK } from 'common/TopNavbar/strings';

const MessageRoutes = ({ eventEmitter, setRouteMessage, userPreferenceData }) => {
  const { errorToast } = useToast();
  const [currentDate, setCurrentDate] = useState(getFormatedDate(new Date(), userPreferenceData?.userTimezone));
  const [events, setEvents] = useState([]);
  const [routeList, setRouteList] = useState([]);
  const [activeIndex, setActiveIndex] = useState([0]);
  const [routeEvent, setRouteEvent] = useState({});
  const [confirmLayer, setConfirmLayer] = useState(false);
  const [confirmData, setConfirmData] = useState('');
  const [selectedEventId, setSelectedEventId] = useState([]);
  const [smsBalanceCount, setSmsBalanceCount] = useState(0);
  const [recipientsCount, setRecipientsCount] = useState(0);
  const [selectedBoardedChildren, setSelectedBoardedChildren] = useState('ALL');
  const [selectedMsgType, setSelectedMsgType] = useState(userPreferenceData?.routeMessageOption);
  const [messageSize, setMessageSize] = useState(0);
  const [isCheckboxDisable, setIsCheckboxDisable] = useState(false);

  const isSmsType = useMemo(() => selectedMsgType?.toLowerCase() === 'sms', [selectedMsgType]);

  const lowBalance = useMemo(() => isSmsType && smsBalanceCount <= 0, [smsBalanceCount, isSmsType]);

  const handleOnChangeDate = useCallback(date => {
    setCurrentDate(date);
    setRouteEvent({});
  }, []);

  const onSubmit = useCallback(
    values => {
      if (!userPreferenceData?.triggerEmails) {
        errorToast(MESSAGE.EMAILS_ARE_CURRENTLY);
        return;
      }
      setConfirmLayer(true);
      setConfirmData(values);
    },
    [userPreferenceData, errorToast],
  );

  useEffect(() => {
    fetchTransportData(eventEmitter, currentDate, 'undefined');
  }, [eventEmitter, currentDate]);

  useEffect(() => {
    if (isSmsType) getRouteSmsBalance({ eventEmitter });
  }, [eventEmitter, isSmsType]);

  useEffect(() => {
    if (selectedEventId?.length && selectedBoardedChildren) {
      setIsCheckboxDisable(true);
      messageAllRoutes({
        eventEmitter,
        confirmData: {
          message: '',
          children_who_have_boarded: selectedBoardedChildren,
          message_type: selectedMsgType,
        },
        selectedEventId,
        recipient_count: true,
        sent_sms: true,
      });
    } else {
      setRecipientsCount(0);
      setIsCheckboxDisable(false);
    }
  }, [eventEmitter, selectedBoardedChildren, selectedEventId, selectedMsgType]);

  useEffect(() => {
    let allSelectedEventId = [];
    let listEvents = Object.keys(routeEvent).map(key => {
      return routeEvent[key];
    });
    listEvents.map(item => {
      for (let x of item) {
        allSelectedEventId.push(x);
      }
    });
    setSelectedEventId(allSelectedEventId);
  }, [routeEvent, events]);

  useEffect(() => {
    const subscription = eventEmitter.getObservable().subscribe(event => {
      switch (event.type) {
        case ROUTE_MESSAGE_EVENTS.FETCH_ROUTE_SUCCESS:
          setEvents(event?.data);
          setRouteList(parseRouteList(event?.data));
          break;
        case ROUTE_MESSAGE_EVENTS.FETCH_ROUTE_FAILURE:
          errorToast(event?.data?.title || ERROR_MESSAGES.FAILED_TO_FETCH_ROUTE_EVENTS);
          break;
        case ROUTE_MESSAGE_EVENTS.FETCH_SUCCESS_ROUTE_SMS_BALANCE:
          setSmsBalanceCount(event?.data?.sms_balance);
          break;
        case ROUTE_MESSAGE_EVENTS.FETCH_FAILURE_ROUTE_SMS_BALANCE:
          errorToast(event?.data?.title || ERROR_MESSAGES.FAILED_TO_FETCH_SMS_BALANCE_COUNT);
          break;
        case ROUTE_MESSAGE_EVENTS.FETCH_SUCCESS_RECIPENTS_COUNT:
          setRecipientsCount(event?.data?.recipients_count || 0);
          setIsCheckboxDisable(false);
          break;
        case ROUTE_MESSAGE_EVENTS.FETCH_FAILURE_RECIPENTS_COUNT:
          errorToast(event?.data?.title || ERROR_MESSAGES.FAILED_TO_FETCH_RECIPIENTS_COUNT);
          setIsCheckboxDisable(false);
          break;
        default:
          break;
      }
    });
    return () => subscription.unsubscribe();
  }, [eventEmitter, routeList, errorToast]);

  return (
    <Box>
      <Formik
        initialValues={{
          message: '',
          children_who_have_boarded: 'ALL',
          message_type: userPreferenceData?.routeMessageOption,
        }}
        enableReinitialize={true}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({ values, handleSubmit, setFieldValue, errors, touched }) => {
          return (
            <>
              <form onSubmit={handleSubmit}>
                <Box direction="row" justify="between" width="100%">
                  <Box direction="row" gap="small" align="center">
                    <DatePicker
                      disableInput={true}
                      name="date"
                      initialDate={currentDate}
                      value={currentDate}
                      onChange={handleOnChangeDate}
                      customFormat={MESSAGE.DATE_CUSTOM_FORMAT}
                      viewDateFormat={viewDateFormat}
                      isconvertBoundsToTimeZone={false}
                    />
                    <Text>{MESSAGE.SELECT_DATES_TO_CHHOSE_ROUTES}</Text>
                  </Box>
                  <Box gap="small" justify="center" align="end" width="50%">
                    {isSmsType && lowBalance && (
                      <Box {...commonStyle}>
                        <Text>{MESSAGE.LOW_THRESHOLD_MESSAGE}</Text>
                      </Box>
                    )}
                    {isSmsType && messageSize > byteSize && (
                      <Box {...commonStyle}>
                        <Text>{MESSAGE.EXCEED_MESSAGE}</Text>
                      </Box>
                    )}
                  </Box>
                </Box>
                <Grid pad={{ top: 'small' }} height="100%" columns={['35%', 'flex']} gap="small">
                  <Box>
                    <StyledHeading level="4">Select Routes to Message</StyledHeading>
                    <Accordion
                      animate={true}
                      multiple
                      activeIndex={activeIndex}
                      onActive={newActiveIndex => setActiveIndex(newActiveIndex)}
                    >
                      <StyledAccordionPanel
                        label={
                          <Box direction="row" gap="small" justify="between" pad="small" width="100%">
                            <StyledText>Message all Routes</StyledText>
                            <CheckBox
                              size="small"
                              checked={checkedState(routeEvent, events)}
                              onClick={e => e.stopPropagation()}
                              onChange={e => onRoutesCheckAll(e.target.checked, events, setRouteEvent)}
                              disabled={isCheckboxDisable}
                            />
                          </Box>
                        }
                      >
                        <Box margin={{ vertical: 'small' }} background="white">
                          <Accordion border={{ side: 'bottom', size: '1px', color: 'lightGreyColor' }} multiple>
                            {routeList
                              ?.sort((a, b) => Object.keys(a)[0].localeCompare(...Object.keys(b)))
                              ?.map((i, index) => (
                                <StyledAccordionPanel
                                  key={index}
                                  label={
                                    <Box direction="row" gap="small" justify="between" pad="small" width="100%">
                                      <StyledText>{Object.keys(i)}</StyledText>
                                      <CheckBox
                                        size="small"
                                        checked={
                                          routeEvent[Object.keys(i)]
                                            ? routeEvent[Object.keys(i)].length === Object.values(i)[0].length
                                              ? true
                                              : false
                                            : false
                                        }
                                        onClick={e => e.stopPropagation()}
                                        onChange={e =>
                                          onCheckAll(
                                            e.target.checked,
                                            Object.values(i)[0],
                                            ...Object.keys(i),
                                            setRouteEvent,
                                          )
                                        }
                                        disabled={isCheckboxDisable}
                                      />
                                    </Box>
                                  }
                                >
                                  {Object.values(i)[0].map((i, index) => (
                                    <Box
                                      key={index}
                                      border={{ side: 'bottom', size: '1px', color: 'lightGreyColor' }}
                                      direction="row"
                                      gap="small"
                                      pad={{ horizontal: 'small', vertical: 'small' }}
                                      justify="between"
                                      width="100%"
                                    >
                                      <StyledText>{i.route}</StyledText>
                                      <CheckBox
                                        size="small"
                                        name={`${index}.checkedItem`}
                                        checked={
                                          routeEvent[i.groupName] ? routeEvent[i.groupName].includes(i.pk) : false
                                        }
                                        onChange={e => onCheck(e.target.checked, i.pk, i.groupName, setRouteEvent)}
                                        disabled={isCheckboxDisable}
                                      />
                                    </Box>
                                  ))}
                                </StyledAccordionPanel>
                              ))}
                          </Accordion>
                        </Box>
                      </StyledAccordionPanel>
                    </Accordion>
                  </Box>
                  <Box>
                    <Box direction="row">
                      <Box direction="column" width="50%">
                        <StyledHeading level="4">{MESSAGE.MESSAGE_CONTENT}</StyledHeading>
                      </Box>
                      <Box direction="column" width="50%" alignSelf="end" margin={{ bottom: '13px' }}>
                        <Button
                          primary
                          type="submit"
                          label={values.message_type === 'SMS' ? MESSAGE.SEND_SMS_MESSAGE : MESSAGE.SEND_EMAIL_MESSAGE}
                          alignSelf="end"
                          color="accent-1"
                          onClick={() => {}}
                          disabled={recipientsCount <= 0 || lowBalance}
                        />
                      </Box>
                    </Box>
                    <Box background="white">
                      <TextArea
                        fill
                        rows="4"
                        name="message"
                        placeholder={'Message to Parents'}
                        values={values.message}
                        onChange={value => {
                          if (isSmsType) {
                            let bytesSize = calculatMessageByteSize(value.target.value);
                            setMessageSize(bytesSize);
                          }
                          setFieldValue('message', value.target.value);
                        }}
                      />
                      {touched.message && errors.message && <Text color="red"> {errors.message}</Text>}
                    </Box>
                    <Box width="100%" direction="row" gap="medium">
                      <Box width="70%">
                        <FormField
                          name="children_who_have_boarded"
                          label={<Box pad={{ bottom: 'small' }}> Select Message Options</Box>}
                        >
                          <Select
                            name="children_who_have_boarded"
                            labelKey="value"
                            value={values.children_who_have_boarded}
                            options={childrenBoardedOptions}
                            valueKey={{ key: 'pk', reduce: true }}
                            onChange={({ value }) => {
                              setSelectedBoardedChildren(value);
                              setFieldValue('children_who_have_boarded', value);
                            }}
                            isClearable={false}
                          />
                        </FormField>
                        <Text color={recipientsColor}>Number of Recipients : {recipientsCount}</Text>
                      </Box>
                      <Box width="30%">
                        <FormField name="message_type" label={<Box pad={{ bottom: 'small' }}> Message Type</Box>}>
                          <Select
                            name="message_type"
                            labelKey="value"
                            value={values.message_type}
                            options={messageTypeOptions}
                            valueKey={{ key: 'pk', reduce: true }}
                            onChange={({ value }) => {
                              setFieldValue('message_type', value);
                              setSelectedMsgType(value);
                            }}
                            isClearable={false}
                            disabled={userPreferenceData?.routeMessageOption === 'EMAIL'}
                          />
                        </FormField>
                        {isSmsType && <Text color={availableMsgColor}>Available Message : {smsBalanceCount}</Text>}
                      </Box>
                    </Box>
                    {isSmsType && (
                      <Link target="_blank" to={LINK} style={{ alignSelf: 'end' }} rel="noreferrer">
                        <Anchor
                          as="span"
                          label="Click to top up message balance"
                          size="medium"
                          margin={{ vertical: 'small' }}
                          color={'secondary'}
                        />
                      </Link>
                    )}
                  </Box>
                </Grid>
              </form>
            </>
          );
        }}
      </Formik>
      {confirmLayer && (
        <ConfirmLayer
          setConfirmLayer={setConfirmLayer}
          currentDate={currentDate}
          confirmData={confirmData}
          events={events}
          setRouteMessage={setRouteMessage}
          errorToast={errorToast}
          selectedEventId={selectedEventId}
          recipientsCount={recipientsCount}
          isSmsType={isSmsType}
          messageSize={messageSize}
          byteSize={byteSize}
          eventEmitter={eventEmitter}
          totalBtyeSize={totalBtyeSize}
        />
      )}
    </Box>
  );
};

MessageRoutes.propTypes = {
  eventEmitter: PropTypes.any,
  setRouteMessage: PropTypes.func,
  userPreferenceData: PropTypes.object,
};

export default MessageRoutes;
