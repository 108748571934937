// Application Imports
import GraniteAPIClient from 'granite-admin/utils/granite-client';
import { env } from 'granite-admin/env';
import axios from 'axios';

import URLS from './end-point.js';

/* eslint-disable */
const url = env.REACT_APP_API_BASE_URL + env.REACT_APP_ROLLCALL_API;

/**
 * Fetches rollover data from the API.
 *
 * @param {Object} [params] - Optional query parameters to include in the request. If not provided, defaults to `undefined`.
 * @returns {Promise<Object>} - A promise that resolves to the data returned by the API.
 */
async function getRollOver(params) {
  return (await GraniteAPIClient(url).get(URLS.ROLLOVER, params ? { params } : undefined))?.data;
}

/**
 * Submits rollover data to the API. Uses PATCH if a primary key (`pk`) is provided; otherwise, uses POST.
 *
 * @param {Object} param - The data to be submitted. If it includes a `pk`, PATCH is used; otherwise, POST is used.
 * @param {number} [param.pk] - Optional primary key for updating an existing resource.
 * @returns {Promise<Object>} - A promise that resolves to the data returned by the API.
 */
async function postRollOver(param) {
  const { pk, ...payload } = param;
  const method = pk ? 'patch' : 'post';
  const endpoint = pk ? `${URLS.ROLLOVER}${pk}/` : URLS.ROLLOVER;
  return (await GraniteAPIClient(url)[method](endpoint, payload))?.data;
}

/**
 * Fetches route group list data from the API.
 *
 * @param {Object} [params] - Optional query parameters to include in the request.
 * @returns {Promise<Object>} - A promise that resolves to the data returned by the API.
 */
async function getRouteGroupList(params) {
  return (await GraniteAPIClient(url).get(URLS.ROUTE, { params }))?.data;
}

async function getTransportData(params) {
  const { data } = await GraniteAPIClient(url).get(`/route-events/`, params);
  const transportData = data
    ? data.results.map(item => {
        const pk = item.pk;
        const date = item.route_date;
        const route_name = item.route_name;
        const eventId = item.route?.pk;
        const eventColor = item.route?.route_color;
        const eventName = item.route?.name;
        const groupName = item.route?.route_group?.group_name;
        const groupId = item.route?.route_group?.pk;
        const routeType = item?.route?.route_type;
        const {
          school_check: schoolCheck,
          supervisor_check: supervisorCheck,
          transport_medium_check: busCheck,
          supervisor_check_datetime,
          supervisor_check_off_datetime,
          transport_medium_check_datetime,
          school_check_datetime,
          driver_login_datetime,
          school_check_off_datetime,
          teacher,
          driver,
          supervisor,
        } = item;
        return {
          pk,
          date,
          eventId,
          eventColor,
          eventName,
          groupName,
          groupId,
          routeType,
          route_name,
          schoolCheck,
          supervisorCheck,
          busCheck,
          supervisor_check_datetime,
          supervisor_check_off_datetime,
          transport_medium_check_datetime,
          school_check_datetime,
          school_check_off_datetime,
          driver_login_datetime,
          teacher,
          driver,
          supervisor,
        };
      })
    : [];
  return transportData;
}

async function fetchTransportStudentData(payload) {
  try {
    const { data } = await GraniteAPIClient(url).post(`/load-student-list/`, payload);
    return data;
  } catch (err) {
    throw new GraniteError(err);
  }
}

async function startRollover(payload, org) {
  const headers = { organisation: org };
  const res = await axios.post('https://nhnmsl4my3satd3jrla3zysesq0pburw.lambda-url.ap-southeast-2.on.aws/', payload, {
    headers,
  });
  return res;
}

async function getRolloverStudentData(params) {
  return (await GraniteAPIClient(url).get(URLS.STUDENT_DATA, params ? { params } : undefined))?.data;
}

async function getActivePlugin() {
  return (await GraniteAPIClient(url).get(URLS.PLUGIN_ACTIVE))?.data;
}

export default {
  getRollOver,
  postRollOver,
  getRouteGroupList,
  getTransportData,
  fetchTransportStudentData,
  startRollover,
  getRolloverStudentData,
  getActivePlugin,
};
