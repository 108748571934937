// Core Imports
import GraniteAPIClient from 'granite-admin/utils/granite-client';

// Application Imports
import GraniteError from 'granite-admin/utils/granite-error';
import { env } from 'granite-admin/env';

/* eslint-disable */
const url = env.REACT_APP_API_BASE_URL + env.REACT_APP_ROLLCALL_API;
const baseUrl = env.REACT_APP_API_BASE_URL + env.REACT_APP_ORGANISATION_API;

async function getOrgSettingAllKey(payload) {
  const { data } = await GraniteAPIClient(baseUrl).get(`/settings/`, {
    params: payload,
  });
  return data?.results;
}

async function sendCustomEmail({ email, message }) {
  try {
    const payload = {
      email: email,
      message: message,
    };
    return await GraniteAPIClient(url).post(`/send-custom-email/`, payload);
  } catch (e) {
    throw new GraniteError(e);
  }
}

export default { sendCustomEmail, getOrgSettingAllKey };
