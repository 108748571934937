/**
 * Contains the API endpoint URLs used in the application.
 *
 * @constant {Object}
 * @property {string} ROLLOVER - The endpoint for rollover-related operations.
 * @property {string} ROUTE - The endpoint for route-related operations.
 */
const URLS = {
  ROLLOVER: '/rollover/',
  ROUTE: '/route/',
  STUDENT_DATA: '/get-rollover-student-data/',
  PLUGIN_ACTIVE: '/active-plugin/upload-csv/',
};

export default URLS;
