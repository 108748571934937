// Lib imports
import _ from 'lodash/fp';

// Core imports
import GraniteError from 'granite-admin/utils/granite-error';
import { env } from 'granite-admin/env';
import { getAuthToken, getOrganisation, getTenantAccess } from 'granite-admin/utils/auth-singleton';

// Application imports
import commonAPI from 'common/commonGatewaysControllers/gateways/common-api';
import {
  COMMON_EVENTS,
  STOPS_EVENTS,
  CAMPUS_EVENTS,
  ROUTES_EVENTS,
  STUDENTS_EVENTS,
  TRANSPORT_EVENTS,
} from 'common/commonGatewaysControllers/controllers/events';

import {
  StopsEntity,
  CampusEntity,
  RouteEntity,
  EventTypeEntity,
  StudentEntity,
} from 'common/commonGatewaysControllers/entities/common';
import { handleApiCall } from 'utils/handleApiCallController';
import { SmartRouteStopEntity } from 'smartRoute/entities/smartRouteStudent';
import { generatePDFPreview } from 'utils/fileUtils';

const authToken = getAuthToken(true);
const organisationId = getOrganisation();
const UnmappedStudsLambda = env.REACT_APP_UNMAPPED_STUDENTS_LAMBDA;

export async function userPhoneValidation(values) {
  if (_.isNil(values)) {
    throw new GraniteError('userData is invalid');
  }
  try {
    return await commonAPI.userPhoneValidation(values);
  } catch (error) {
    throw new GraniteError(error);
  }
}

/**
 * Dashboard myboard and OP screens- Get the event groups.
 *
 * @param {object} - eventEmitter
 *
 * @returns {eventEmitter} - In the try block, Emits a success events with event groups list
 * @returns {eventEmitter} - In the catch block, Emits a failure event if failed to fetch
 */
export async function fetchEventGroups(eventEmitter) {
  const payload = { params: { ordering: 'group_name', page_size: 10000 } };
  return await handleApiCall(
    async () => {
      const { data } = await commonAPI.getEventGroups(payload);
      const eventGroupList = data ? data.results?.map(item => new EventTypeEntity(item)) : [];
      eventEmitter.emit(COMMON_EVENTS.FETCH_EVENT_GROUP_SUCCESS, eventGroupList);
    },
    eventEmitter,
    COMMON_EVENTS.FETCH_EVENT_GROUP_FAILURE,
  );
}

/**
 * Fetches all stops from API
 *
 * @param {Object} eventEmitter - The object that dispatches events
 * @param {Object} params - The object containing the filter and
 *                          parameters.
 *
 * @returns {Promise} - A promise resolving to an object containing the
 *                     stops data, its count.
 * emits STOPS_EVENTS.STOPS_LIST_FETCH_FAILURE in case of failure
 */

export async function getStopsList(eventEmitter, params) {
  return await handleApiCall(
    async () => {
      const payload = { params: { ...params, ordering: params?.ordering === null ? 'name' : params?.ordering } };
      const { data } = await commonAPI.getStopsList(payload);
      const listValue = [];
      const EntityType = params?.smart_route_screen ? SmartRouteStopEntity : StopsEntity;
      for (let stop = 0; stop < data?.results?.length; stop++) {
        listValue.push(new EntityType(data?.results[stop]));
      }
      return { list: listValue, count: data?.count || listValue?.length };
    },
    eventEmitter,
    STOPS_EVENTS.STOPS_LIST_FETCH_FAILURE,
  );
}

/**
 * Fetches a single stop from API
 *
 * @param {Object} eventEmitter - The object that dispatches events
 * @param {Object} params - The object containing the primary key of the stop
 *                          to be fetched.
 *
 * @returns {Promise} - A promise resolving to an object containing the
 *                     stop data.
 * emits STOPS_EVENTS.STOP_FETCH_FAILURE in case of failure
 */
export async function getOneStop(eventEmitter, params) {
  return await handleApiCall(
    async () => {
      const { pk } = params || {};
      const { data } = await commonAPI.getOneStop(pk);
      return new StopsEntity(data);
    },
    eventEmitter,
    STOPS_EVENTS.STOP_FETCH_FAILURE,
  );
}

/**
 * Fetches all campuses from API
 *
 * @param {Object} eventEmitter - The object that dispatches events
 * @param {Object} params - The object containing the filter and
 *                          parameters.
 *
 * @returns {Promise} - A promise resolving to an object containing the
 *                     campus data, its count.
 * emits CAMPUS_EVENTS.CAMPUS_LIST_FETCH_FAILURE in case of failure
 */
export async function getCampus(eventEmitter, params) {
  return await handleApiCall(
    async () => {
      const payload = { params: { ...params, ordering: params?.ordering === null ? 'name' : params?.ordering } };
      const { data } = await commonAPI.getCampus(payload);
      const list = data ? data.results?.map(item => new CampusEntity(item)) : [];
      return { list, count: data.count };
    },
    eventEmitter,
    CAMPUS_EVENTS.CAMPUS_LIST_FETCH_FAILURE,
  );
}
/**
 * Deletes a stop from API
 *
 * @param {Object} eventEmitter - The object that dispatches events
 * @param {Number} stopId - The primary key of the stop
 *
 * @returns {Promise} - A promise resolving to an object containing the
 *                     deleted stop data
 * emits STOPS_EVENTS.STOP_DELETE_FAILURE in case of failure
 */
export async function deleteStop(eventEmitter, stopId) {
  return await handleApiCall(
    async () => {
      let { data } = await commonAPI.deleteStop(stopId);
      eventEmitter.emit(STOPS_EVENTS.STOP_DELETE_SUCCESS, data);
      return data;
    },
    eventEmitter,
    STOPS_EVENTS.STOP_DELETE_FAILURE,
  );
}

/**
 * Fetches a single route from API
 *
 * @param {Object} eventEmitter - The object that dispatches events
 * @param {Number} pk - The primary key of the route
 *
 * @returns {Promise} - A promise resolving to an object containing the
 *                     route data
 * emits ROUTES_EVENTS.ROUTE_FETCH_FAILURE in case of failure
 */

export async function getSingleRoute(eventEmitter, pk) {
  return await handleApiCall(
    async () => {
      const { data } = await commonAPI.getSingleRoute(pk);
      return new RouteEntity(data);
    },
    eventEmitter,
    ROUTES_EVENTS.ROUTE_FETCH_FAILURE,
  );
}

/**
 * Downloads a PDF of unmapped students
 *
 * @param {Object} eventEmitter - The object that dispatches events
 * @param {Object} data - The object containing the pagination information
 *
 * @returns {Promise} - A promise resolving to an object containing the
 *                     buffer data of the PDF
 * emits STUDENTS_EVENTS.STUDENTS_PDF_GENERATION_SUCCESS in case of success
 * emits STUDENTS_EVENTS.STUDENTS_PDF_GENERATION_FAILURE in case of failure
 */
export async function generateUnmappedStudPdf(eventEmitter, data) {
  return await handleApiCall(
    async () => {
      const URL = `${UnmappedStudsLambda}?page=${data.page}&page_size=${data.page_size}&is_active=${data.is_active}`;
      const tenantAccess = getTenantAccess();
      const headersData = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        organisation: organisationId,
        authorization: `${authToken}`,
      };

      const headers = tenantAccess
        ? {
            ...headersData,
            'Tenant-access': tenantAccess,
          }
        : headersData;

      const res = await fetch(URL, {
        method: 'post',
        headers: headers,
      });
      const bufferData = await res.arrayBuffer();
      generatePDFPreview(bufferData);
      eventEmitter.emit(STUDENTS_EVENTS.STUDENTS_PDF_GENERATION_SUCCESS);
    },
    eventEmitter,
    STUDENTS_EVENTS.STUDENTS_PDF_GENERATION_FAILURE,
  );
}
/**
 * Fetch the stops arrival times. and emits FETCH_STOPS_ARRIVAL_TIMES with list
 * emits FAILURE_FETCH_STOPS_ARRIVAL_TIMES in case of failure
 */
export async function fetchStopsArrivalTime(values) {
  const { eventEmitter, routeIds: pks } = values;
  handleApiCall(
    async () => {
      const routeIds = JSON.stringify(pks);
      const data = await commonAPI.stopsArrivalTime(routeIds);
      let listValue = [];
      for (let stop = 0; stop < data?.results?.length; stop++) {
        let item = data?.results?.[stop];
        listValue.push({
          pk: item?.stop_id,
          routeId: item?.route_id,
          expectedArrivalTime: item?.expected_arrival_time,
        });
      }
      eventEmitter.emit(TRANSPORT_EVENTS.FETCH_STOPS_ARRIVAL_TIMES, listValue);
    },
    eventEmitter,
    TRANSPORT_EVENTS.FAILURE_FETCH_STOPS_ARRIVAL_TIMES,
  );
}

/* Fetch student list
 *
 * @param {object} values - The object containing eventEmitter and params
 * @param {object} eventEmitter - The event emitter for emitting success and failure events
 * @param {object} params - The object containing query params
 *
 * @returns {Promise<Object>} - The promise resolves with the student list
 * @returns {eventEmitter} - Emits a failure event if failed to fetch
 */
export async function fetchStudentList(values) {
  const { eventEmitter, params } = values;
  return await handleApiCall(
    async () => {
      const payload = { params: { ...params, is_active: true, ordering: 'first_name' } };
      const { data } = await commonAPI.getStudent(payload);
      const list = data ? data.results?.map(item => new StudentEntity(item)) : [];
      return { list, count: data.count };
    },
    eventEmitter,
    COMMON_EVENTS.FETCH_STUDENT_FAILURE,
  );
}
