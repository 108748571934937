import React, { useState, useEffect, useMemo, useContext } from 'react';
import { connect, useSelector } from 'react-redux';
import { Link, matchPath } from 'react-router-dom';
import { Box, Image, Heading, Menu, Header, Text, Layer, Button } from 'grommet';
import { FormClose } from 'grommet-icons';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import * as SidebarDucks from 'granite-admin/accounts/ducks/sidebar';
import * as UserDucks from 'granite-admin/accounts/ducks/user';
import * as OrganisationDucks from 'granite-admin/organisations/ducks/organisations';
import Loader from 'granite-admin/core/components/Loader';
import { loadMenuItemsAndPermissions } from 'granite-admin/accounts/controllers/sidebar';
import useWindowDimensions from 'granite-admin/utils/useWindowDimensions';
import useUtility from 'granite-admin/utils/useUtility';
import { ConfigContext } from 'granite-admin/core/components/ConfigProvider';

import logo from 'assets/logo.png';
import MenuButton from './components/MenuButton';
import UserDropMenu from './components/UserDropMenu';
import Notification from './components/Notification';
import { getOrganisationAvatar } from './components/UserDropMenu';
import LeftItems from './components/LeftItems';
import RightItems from './components/RightItems';
import MoreLeftItems from './components/MoreLeftItems';
import { parseTopMenuItems } from './utils';
import { MARKERPLACE_MESSAGING_DATA } from './controllers/constants';

const HeaderBox = styled(Header)`
  top: 0;
  left: 0;
  z-index: 1;
  padding: 0px 10px;
`;

function fetchSidemenuItems(dispatch) {
  loadMenuItemsAndPermissions()
    .then(response => {
      dispatch({
        type: 'accounts/sidebar/UPDATE_SIDEBAR',
        data: response,
      });
    })
    .catch(error => console.log('page error', error));
}

const getItemCount = dimension => {
  if (dimension.width < 900) return 4;
  else if (dimension.width < 1000) return 5;
  else if (dimension.width < 1200) return 6;
  else if (dimension.width < 1400) return 7;
  else if (dimension.width < 1600) return 8;
  else return 9;
};

const TopNavbar = ({
  sidebarItems,
  navigate,
  pathname,
  title,
  userProfile,
  dispatch,
  theme,
  showUserDropMenu,
  selectedOrganisation,
  getItemCount,
}) => {
  const config = useContext(ConfigContext);
  const { TopNavCustomLeftComp, TopNavCustomRightComp } = config?.sidebarSettings;
  const [openSupport, setOpenSupport] = useState(false);
  const [routeMessage, setRouteMessage] = useState(false);
  const [loader, setLoader] = useState(true);

  const windowDimensions = useWindowDimensions();

  const userPreferenceData = useSelector(({ accounts }) => accounts?.user?.profile);

  const grandListner = React.useCallback(
    event => {
      switch (event.type) {
        case 'UPDATE_SIDEBAR':
          fetchSidemenuItems(dispatch);
          break;
        default:
          break;
      }
    },
    [dispatch],
  );

  useUtility({ grandListner });

  useEffect(() => {
    if (sidebarItems.length === 0) fetchSidemenuItems(dispatch);
  }, [dispatch, sidebarItems]);

  const signOut = () => {
    navigate('/logout');
  };

  const navItems = useMemo(
    () => parseTopMenuItems(sidebarItems, userPreferenceData, selectedOrganisation),
    [sidebarItems, userPreferenceData, selectedOrganisation],
  );

  const menuItems = useMemo(() => {
    const leftObj = navItems.find(i => i.group === 'left');
    return {
      visibleItems: [...leftObj.children].splice(0, getItemCount(windowDimensions)),
      moreItems: [...leftObj.children].splice(getItemCount(windowDimensions)),
      rightItems: { children: selectedOrganisation?.pk ? MARKERPLACE_MESSAGING_DATA : [] },
    };
  }, [navItems, getItemCount, windowDimensions]);

  return (
    <>
      <HeaderBox
        background={theme.global.colors['sidebar-background']}
        fill="horizontal"
        direction="row"
        gap="small"
        wrap
      >
        {sidebarItems.length === 0 ? (
          <Box align="center" justify="center" fill>
            <Loader size="30px" />
          </Box>
        ) : (
          <>
            <Box direction="row" flex={{ shrink: 0 }} margin={{ right: 'auto' }} gap="small">
              <Box justify="center" flex={{ shrink: 0 }}>
                {selectedOrganisation?.logo?.thumbnails ? (
                  <Image src={selectedOrganisation.logo.thumbnails} alt="Company Logo" />
                ) : typeof title === 'string' ? (
                  <Heading margin="none" level={3}>
                    {title}
                  </Heading>
                ) : (
                  title
                )}
              </Box>
              <Box direction="row" alignSelf="center" gap="xsmall" align="center">
                {menuItems.visibleItems.map(child => renderLeftItem(child, theme, pathname))}
                {menuItems.moreItems.length > 0 && <MoreLeftItems subItems={menuItems.moreItems} theme={theme} />}
                {TopNavCustomLeftComp && selectedOrganisation?.pk && <TopNavCustomLeftComp />}
              </Box>
            </Box>
            <Box direction="row" flex={{ shrink: 0 }} margin={{ left: 'auto' }}>
              <Box direction="row" alignSelf="center" gap="xsmall">
                {TopNavCustomRightComp && <TopNavCustomRightComp />}
                {menuItems.rightItems.children.map(child => (
                  <RightItems
                    key={child.pk}
                    item={child}
                    theme={theme}
                    setOpenSupport={setOpenSupport}
                    setRouteMessage={setRouteMessage}
                    routeMessage={routeMessage}
                  />
                ))}
              </Box>

              <Box direction="row" flex={{ shrink: 0 }} justify="center" align="center" gap="small">
                <Notification navigate={navigate} />
                {showUserDropMenu ? (
                  <UserDropMenu
                    userProfile={userProfile}
                    selectedOrganisation={selectedOrganisation}
                    navigate={navigate}
                    signOut={signOut}
                    dispatch={dispatch}
                  />
                ) : (
                  <Menu
                    plain
                    items={[
                      { label: 'Profile', onClick: () => navigate('/profile') },
                      { label: 'Logout', onClick: () => navigate('/logout') },
                    ]}
                  >
                    <Box direction="row" align="center" gap="small" pad={{ horizontal: 'small' }}>
                      <Text>{userProfile.firstName}</Text>
                      {getOrganisationAvatar(userProfile)}
                    </Box>
                  </Menu>
                )}
              </Box>
            </Box>
          </>
        )}
        {openSupport && (
          <Layer
            position="center"
            onClickOutside={() => {
              setOpenSupport(false);
              setLoader(true);
            }}
            style={{ background: 'transparent', border: 'none' }}
          >
            <Box direction="row" align="start" width="medium">
              <Button
                alignSelf="end"
                icon={<FormClose size="medium" color="#000" />}
                onClick={() => setOpenSupport(false)}
                plain
              />
            </Box>
            {loader ? (
              <Box align="center" justify="center">
                <Loader />
              </Box>
            ) : null}
            <iframe
              title="Help & Support"
              src={
                'https://rollcall.freshdesk.com/widgets/feedback_widget/new?&widgetType=popup&widgetView=no&submitTitle=Send+Report&submitThanks=Thank+you+for+your+report'
              }
              width="850"
              height="500"
              style={{ background: 'transparent', border: 'none' }}
              onLoad={() => setLoader(false)}
            />
          </Layer>
        )}
      </HeaderBox>
    </>
  );
};

const StyledMenuButton = styled(MenuButton)`
  &:hover {
    background: none;
    border-bottom: ${({ theme }) => `1px solid ` + theme.global.colors['sidebar-svg-active'] + `!important`};
  }
`;

const renderLeftItem = (item, theme, pathname) => {
  const itemId = item.pk || item.getlabel();
  const isActive = Boolean(matchPath({ path: '/' + item.path }, pathname));
  const content = item?.children?.length ? (
    <LeftItems
      label={item.label}
      theme={theme}
      subItems={item.children}
      isActive={isActive}
      activeColor={theme.global.colors['sidebar-svg-active']}
      pathname={item.path}
    />
  ) : (
    <StyledMenuButton
      label={item.label}
      id={itemId}
      isActive={isActive}
      activeColor={theme.global.colors['sidebar-svg-active']}
      pathname={item.path}
      menuItemHPad="small"
      item={item}
    />
  );
  return (
    <Box key={itemId} flex={{ shrink: 0 }}>
      {item?.children?.length ? (
        <>{content}</>
      ) : (
        <Link to={'/' + item.path} state={{ url: item.url }}>
          {content}
        </Link>
      )}
    </Box>
  );
};

TopNavbar.propTypes = {
  navigate: PropTypes.object,
  pathname: PropTypes.string,
  title: PropTypes.any,
  userProfile: PropTypes.object,
  sidebarItems: PropTypes.array,
  dispatch: PropTypes.func,
  theme: PropTypes.object,
  showUserDropMenu: PropTypes.bool,
  selectedOrganisation: PropTypes.object,
  getItemCount: PropTypes.func,
};

TopNavbar.defaultProps = {
  title: <Image src={logo} alt="Logo" height="30px" />,
  getItemCount: getItemCount,
};

const mapStateToProps = state => ({
  sidebarItems: SidebarDucks.sidebarItems(state),
  userProfile: UserDucks.profile(state),
  userPermissions: UserDucks.permissions(state),
  selectedOrganisation: OrganisationDucks.selectedOrganisation(state),
});

const mapDispatchToProps = dispatch => ({
  dispatch: dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(TopNavbar);
