// Core Imports
import graniteAPIClient from 'granite-admin/utils/granite-client';

// Application Imports
import {
  URLS,
  STOPS_URLS,
  CAMPUS_URLS,
  ROUTE_URLS,
  ROUTE_MAPPING_URLS,
} from 'common/commonGatewaysControllers/gateways/endpoints';
import { ROLLCALL_API_URL } from 'common/completeUrls';

async function userPhoneValidation(params) {
  const payload = { new_phone: params?.phone, user_id: params?.userId };
  const { data } = await graniteAPIClient(ROLLCALL_API_URL).post(URLS.USER_PHONE_VALIDATION, payload);
  return data;
}

/**
 * Fetches the event groups
 *
 * @param {object} payload - page and page_size
 *
 * @returns {Promise<Object>} - with list of event groups
 */
async function getEventGroups(payload) {
  return await graniteAPIClient(ROLLCALL_API_URL).get(URLS.ROUTE_GROUP, payload);
}

/**
 * Fetches all stops from API
 *
 * @param {Object} payload - The object containing the payload
 *
 * @returns {Promise} - A promise resolving to an object containing the
 *                     stops data, its count.
 */
async function getStopsList(payload) {
  return await graniteAPIClient(ROLLCALL_API_URL).get(STOPS_URLS.LIST_STOPS, payload);
}

/**
 * Fetches a single stop from API
 *
 * @param {Number} pk - The primary key of the stop
 *
 * @returns {Promise} - A promise resolving to an object containing the
 *                     stop data
 */
async function getOneStop(pk) {
  return await graniteAPIClient(ROLLCALL_API_URL).get(`${STOPS_URLS.STOP}${pk}/`);
}

/**
 * Deletes a stop from API
 *
 * @param {Number} stopId - The primary key of the stop
 * @returns {Promise} - A promise resolving to an object containing the
 *                     deleted stop data
 */
async function deleteStop(stopId) {
  return await graniteAPIClient(ROLLCALL_API_URL).delete(`${STOPS_URLS.STOP}${stopId}/`);
}

/**
 * Fetches all campuses from API
 *
 * @param {Object} payload - The object containing the payload
 * @returns {Promise} - A promise resolving to an object
 *                     containing the campus data, its count.
 */
async function getCampus(payload) {
  return await graniteAPIClient(ROLLCALL_API_URL).get(CAMPUS_URLS.CAMPUS, payload);
}

/**
 * Fetches a single route from API
 * @param {Number} pk - The primary key of the route
 * @returns {Promise} - A promise resolving to an object containing the
 *                     route data
 */
async function getSingleRoute(pk) {
  return await graniteAPIClient(ROLLCALL_API_URL).get(`${ROUTE_URLS.ROUTE}${pk}/`);
}

/**
 * Fetch the stops arrival times and returns data property from response.
 */
async function stopsArrivalTime(routeIds) {
  const { data } = await graniteAPIClient(ROLLCALL_API_URL).get(
    `${ROUTE_MAPPING_URLS.LIST_ROUTE_STOP_MAPPING}?route_ids=${routeIds}`,
  );
  return data;
}

/* Fetches the list of students
 *
 * @param {object} payload - page, page_size
 *
 * @returns {Promise<Object>} - with list of students
 */
async function getStudent(payload) {
  return await graniteAPIClient(ROLLCALL_API_URL).get(URLS.LIST_STUDENT, payload);
}

export default {
  userPhoneValidation,
  getEventGroups,
  getStopsList,
  getOneStop,
  deleteStop,
  getCampus,
  getSingleRoute,
  stopsArrivalTime,
  getStudent,
};
