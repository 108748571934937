// Lib imports
import _ from 'lodash/fp';

// Core imports
import GraniteError from 'granite-admin/utils/granite-error';
import organisationAPIGateway from 'granite-admin/organisations/gateways/organisation-api';
import { getUserPreferences } from 'granite-admin/accounts/controllers/user.js';
import { PROFILE_EVENTS } from 'granite-admin/accounts/controllers/constants';

import { getSettings } from 'companySettings/controllers/organisation';
import {
  getAuthToken,
  setAuthToken,
  resetAuthToken,
  getOrganisation,
  setOrganisation,
  resetSsoLogin,
  getTenantAccess,
  setTenantAccess,
} from 'granite-admin/utils/auth-singleton';

import userApi from 'accounts/gateways/user_api';
// import TopNavbar from 'common/TopNavbar/gateways/routeMessage-api';
import { LOGIN_EVENTS, RESET_EVENTS, USER_PREFERENCES_EVENTS } from './constants';

let config = {};
if (process.env.REACT_APP_CONFIG_FILE)
  import(process.env.REACT_APP_CONFIG_FILE)
    .then(({ default: configObj }) => (config = configObj))
    .catch(e => console.log('Module Not Found', e));

const registerDisabled = process.env.REACT_APP_REGISTER_DISABLED || false;

async function getMyProfile(eventEmitter, showToast = true) {
  try {
    const currentDomain = window.location;
    const subdomain = currentDomain.host.split('.')[0] ? currentDomain.host.split('.')[0] : '';

    const profile = await userApi.myProfile();
    const redirectedOrg = profile?.organisations?.filter(org => org.domain === subdomain);
    const localOrganisation = getOrganisation();
    let orgId = null;
    if (localOrganisation && localOrganisation !== 'undefined') {
      orgId = localOrganisation;
    } else if (profile.isSystemAdmin && profile.organisations.length > 0 && getTenantAccess()) {
      if (redirectedOrg[0]) {
        orgId = redirectedOrg[0].pk;
      } else {
        orgId = profile.organisations[0]?.pk;
      }
    } else if (!profile.isSystemAdmin) {
      orgId = redirectedOrg[0]?.pk;
    }
    if (orgId) {
      // FETCH PERMISSIONs & Org Detials
      const [myPermissions, orgDetail] = await Promise.all([
        organisationAPIGateway.getEmployeePermissions(orgId),
        organisationAPIGateway.getOrganisationDetail(orgId),
      ]);
      profile.setPermissions(myPermissions.map(i => i.pk));
      profile.setPermissionsName(myPermissions.map(i => i.name));
      profile.setCurrentOrganisation(orgDetail);
      profile.setMigratedDate(orgDetail?.extraData?.migrated_date);
      setOrganisation(orgId);
    } else if (profile.isSystemAdmin) {
      const myPermissions = await organisationAPIGateway.getEmployeePermissions(orgId);
      profile.setPermissions(myPermissions.map(i => i.pk));
      profile.setCurrentOrganisation({});
      profile.setPermissionsName(myPermissions.map(i => i.name));
    } else {
      profile.setPermissions([]);
      profile.setCurrentOrganisation({});
      profile.setPermissionsName([]);
    }
    const userPref = await getUserPreferences();
    const userSetting = await getSettings(eventEmitter, orgId);

    profile.setNavigationLayout('top_menu_bar');

    userPref.forEach(pref => {
      switch (pref?.preferenceKey) {
        case 'user_timezone':
          profile.setTimezone(pref?.value);
          break;
        case 'is_2fa_enabled':
          profile.set2FA(pref?.value);
          profile.set2FAId(pref?.pk);
          break;
        case 'datetime_format':
          profile.setDateTimeFormat(pref?.value);
          break;
        case 'date_format':
          profile.setDateFormat(pref?.value);
          break;
        case 'time_format':
          profile.setTimeFormat(pref?.value);
          break;
        case 'navigation_bar':
          profile.setNavigationLayout('top_menu_bar');
          break;
        case 'two_fa_options':
          profile.setTwoFAOption(pref?.value);
          break;
        default:
          profile.setRestPreferences(pref);
          break;
      }
    });
    userSetting?.length > 0 &&
      userSetting.forEach(user => {
        switch (user?.name) {
          case 'USER_TIMEZONE':
            profile.setUserTimezone(user?.value);
            break;
          case 'DATETIME_FORMAT':
            profile.setUserDateTimeFormat(user?.value);
            break;
          case 'DATE_FORMAT':
            profile.setUserDateFormat(user?.value);
            break;
          case 'TIME_FORMAT':
            profile.setUserTimeFormat(user?.value);
            break;
          case 'payments_module_active':
            profile.setPaymentModuleActive(user?.value?.toLowerCase());
            break;
          case 'parent_app_tabs':
            profile.setParentAppTabs(user?.value);
            break;
          case 'ENABLE_2FA':
            profile.setUserTwoFA(user?.value?.toLowerCase());
            break;
          case 'data_feed_source_warning':
            profile.setUserDataSourceWarning(user?.value?.toLowerCase());
            break;
          case 'redirection_alert_emails':
            profile.setRedirectionAlertsEmails(user?.value);
            break;
          case 'portal_email_on':
            profile.setPortalEmailOn(user?.value?.toLowerCase());
            break;
          case 'portal_sms_on':
            profile.setPortalSmsOn(user?.value?.toLowerCase());
            break;
          case 'pp_absence_on':
            profile.setPpAbsenceOn(user?.value?.toLowerCase());
            break;
          case 'stop_geofence_size':
            profile.setStopGeofenceSize(user?.value);
            break;
          case 'trigger_emails':
            profile.setTriggerEmails(user?.value?.toLowerCase());
            break;
          case 'country':
            profile.setCountry(user?.value?.toLowerCase());
            break;
          case 'parent_bus_change_future_visibility_days':
            profile.setPortalFutureVisibilityDays(user?.value);
            break;
          case 'parent_bus_change_future_editable_days':
            profile.setPortalFutureEditableDays(user?.value);
            break;
          case 'stop_duration_default':
            profile.setStopDurationDefault(user?.value);
            break;
          case 'bus_changes_hide_delete_button':
            profile.setbusChangesHideDeleteButton(user?.value);
            break;
          case 'is_global_dashboard_enabled':
            profile.setisEnableGlobalDashboard(user?.value);
            break;
          case 'is_logo_enabled':
            profile.setIsLogoEnabled(user?.value?.toLowerCase());
            break;
          case 'is_smart_route_enabled':
            profile.setIsSmartRouteEnable(user?.value?.toLowerCase());
            break;
          case 'portal_bus_change_strict_mode':
            profile.setPortalBusChangeEnable(user?.value);
            break;
          case 'transport_manifest_mongo':
            profile.setTransportManifestLambda(user?.value);
            break;
          case 'display_stop_times_dynamically':
            profile.setDisplayStopTimes(user?.value);
            break;
          case 'transport_screen_api':
            profile.setTransportScreenAPI(user?.value);
            break;
          case 'is_dashlets_enabled':
            profile.setIsDashletsEnabled(user?.value?.toLowerCase());
            break;
          case 'route_message_option':
            profile.setRouteMessageOption(user?.value);
            break;
          default:
            profile.setRestSettings(user);
            break;
        }
      });
    if (eventEmitter) eventEmitter.emit(PROFILE_EVENTS.PROFILE_POST_SUCCESS, Object.assign({ showToast }, profile));
    else return profile;
  } catch (error) {
    let errorMessage = JSON.stringify(error);
    if (error.response) errorMessage = error.response.data.message;
    if (error.message) errorMessage = error.message;
    if (eventEmitter && showToast) eventEmitter.emit(PROFILE_EVENTS.PROFILE_POST_FAILURE);
    throw new GraniteError(errorMessage);
  }
}

async function forgetParentPassword(eventEmitter, data) {
  const payload = Object.entries(data).reduce((a, [k, v]) => (v === null ? a : ((a[k] = v), a)), {});
  try {
    await userApi.resetParentPassword(payload);
    eventEmitter.emit('RESET_SUCCESS');
  } catch (error) {
    eventEmitter.emit('RESET_FAILURE', error?.errors?.title);
    throw error;
  }
}

async function isSSOBtnVisible(eventEmitter, subdomain) {
  if (_.isNil(subdomain)) {
    throw new GraniteError('userData is invalid');
  }
  try {
    const { data } = await userApi.isSSOBtnVisible(subdomain);
    eventEmitter.emit(LOGIN_EVENTS.SSO_SUCCESS, data);
  } catch (error) {
    eventEmitter.emit(LOGIN_EVENTS.SSO_FAILURE, error?.errors?.title);
  }
}

async function loginClicked(eventEmitter, { email, password, organisation_id, screen }, config = {}) {
  if (_.isNil(email) || _.isNil(password)) {
    throw new GraniteError('Credentials are invalid');
  }
  const host = window.location.host;
  const currentSubdomain = host.split('.')[0];
  try {
    const data = await userApi.login(email, password, organisation_id, currentSubdomain, screen);
    // const adminRedirect = data?.admin_redirect;
    const orgId = data.organisation_id;
    const isOrgMapped = data.valid_domain;
    if (data.is_2fa_enabled) {
      eventEmitter.emit(LOGIN_EVENTS.LOGIN_SUCCESS, { orgId, screen, isOrgMapped, email });
    } else {
      setAuthToken({
        jwt_token: data.jwt_token,
        jwt_token_expiry: data.jwt_token_expiry,
        bearerToken: data?.access_token,
      });
      if (registerDisabled) {
        const userExist = await checkUserExistence();
        if (!userExist) return eventEmitter.emit(LOGIN_EVENTS.USER_NOT_EXIST);
      }
      checkSubDomain(eventEmitter, orgId, screen, isOrgMapped); //config.subdomainRedirectEnabled is true for rollcall
      // else eventEmitter.emit(LOGIN_EVENTS.LOGIN_SUCCESS);
    }
  } catch (error) {
    eventEmitter.emit(LOGIN_EVENTS.LOGIN_FAILURE, error?.errors);
    // throw error;
  }
}

async function checkSubDomain(eventEmitter, orgId, screen, isOrgMapped) {
  const host = window.location.host;
  const currentSubdomain = host.split('.')[0];
  const profile = await userApi.myProfile();

  let userSubdomain = profile?.organisations?.find(elem => elem?.domain === currentSubdomain)?.domain || '';

  if (screen === 'parent') {
    if (orgId) {
      setOrganisation(orgId);
      setTenantAccess(true);
    }
    eventEmitter.emit(LOGIN_EVENTS.LOGIN_SUCCESS);
  } else if (userSubdomain) {
    if (profile.isSystemAdmin) {
      // usersubdomain and BA + employee
      if (isOrgMapped) eventEmitter.emit('BA_CAN_BE_EMPLOYEE', { orgId });
      else {
        //BA user that is only emp for this org (not mapped to this org)
        setTenantAccess(true);
        eventEmitter.emit(LOGIN_EVENTS.LOGIN_SUCCESS);
      }
    } else {
      //only emp in this org
      eventEmitter.emit(LOGIN_EVENTS.LOGIN_SUCCESS);
    }
  } else {
    if (profile.isSystemAdmin) {
      const serverURL = process.env.REACT_APP_MAIN_URL.replace('https://', '').replace('http://', '');
      const businessDomain = host.substring(
        0,
        host.length - (window.location.port.length + (window.location.port.length ? 1 : 0)),
      );
      if (businessDomain === serverURL) {
        const token = getAuthToken();
        let URL = `${window.location.protocol}//${profile.organisations[0]?.domain}.${host}/login?access_token=${token}&tokenLogin=yes`;
        //  No usersubdomain and BA + employee
        if (profile?.organisations?.length > 0) eventEmitter.emit('BA_CAN_BE_EMPLOYEE', { url: URL });
        else eventEmitter.emit(LOGIN_EVENTS.LOGIN_SUCCESS); //only BA logging in to the BA console
      } else {
        if (isOrgMapped && orgId) {
          //BA mapped to this so PTA
          setOrganisation(orgId);
          eventEmitter.emit(LOGIN_EVENTS.LOGIN_SUCCESS);
        } else {
          //BA is not mapped to this org so can't PTA
          resetAuthToken();
          localStorage.clear();
          eventEmitter.emit('ONLY_BA_ACCESSING_WRONG_ORG');
        }
      }
    } else {
      //user accessing wrong URL
      resetAuthToken();
      eventEmitter.emit('ONLY_PARENT_LOGIN');
    }
  }

  // if (userSubdomain && userSubdomain !== currentSubdomain) {
  //   const token = getAuthToken();
  //   const domain = host.substring(
  //     host.indexOf('.') + 1,
  //     host.length - (window.location.port.length + (window.location.port.length ? 1 : 0)),
  //   );
  //   resetAuthToken();
  //   let URL = `${window.location.protocol}//${userSubdomain}.${domain}${
  //     window.location.port ? `:${window.location.port}` : ''
  //   }/login?access_token=${token}&tokenLogin=yes`;

  //   if (profile.isSystemAdmin && profile.organisations.length) {
  //     eventEmitter.emit('BA_CAN_BE_EMPLOYEE', URL);
  //   } else window.location = URL;
  // } else if (profile.isSystemAdmin && profile.organisations.length) {
  //   eventEmitter.emit('BA_CAN_BE_EMPLOYEE');
  // } else eventEmitter.emit(LOGIN_EVENTS.LOGIN_SUCCESS);
}

async function checkUserExistence() {
  const profile = await userApi.myProfile();
  if (!profile.isSystemAdmin && profile?.organisations?.length === 0) {
    resetAuthToken();
    resetSsoLogin();
    return false;
  }
  return true;
}

async function otpSubmit(eventEmitter, values, state) {
  try {
    const data = await userApi.otpSubmit(values);
    setAuthToken({
      jwt_token: data.jwt_token,
      jwt_token_expiry: data.jwt_token_expiry,
      bearerToken: data?.access_token,
    });
    checkSubDomain(eventEmitter, state?.orgId, state?.screen, state?.isOrgMapped);
    // eventEmitter.emit('OTP_SUCCESS');
  } catch (error) {
    eventEmitter.emit('OTP_FAILURE');
    throw error;
  }
}

async function resetPasswordClicked(eventEmitter, { jwt_token, password, confirm_password }) {
  if (_.isNil(jwt_token) || _.isNil(password) || _.isNil(confirm_password)) {
    throw new GraniteError('Uid, password and re_password is required');
  }

  try {
    await userApi.resetPassword(jwt_token, password, confirm_password);
    eventEmitter.emit(RESET_EVENTS.RESET_PASSWORD_SUCCESS);
  } catch (error) {
    eventEmitter.emit(RESET_EVENTS.RESET_PASSWORD_FAILURE, error?.errors?.title);
    throw error;
  }
}

async function changeUserPreference(eventEmitter, values) {
  try {
    await Promise.all(values.map(async val => userApi.changeUserPreference(val.pk, val.value)));
    eventEmitter.emit(USER_PREFERENCES_EVENTS.CHANGE_USER_PREFERENCES_SUCCESS);
    getMyProfile(eventEmitter, false);
  } catch (error) {
    eventEmitter.emit(USER_PREFERENCES_EVENTS.CHANGE_USER_PREFERENCES_FAILURE);
    throw error;
  }
}

async function updateProfileClicked(eventEmitter, { first_name, last_name }, user = {}) {
  const { updateMyProfile } = userApi;
  if (_.isNil(first_name) && _.isNil(last_name)) {
    throw new GraniteError('Credentials are invalid');
  }
  try {
    const response = await updateMyProfile(first_name, last_name);
    let { permissions, organisations, permissionsName, currentOrganisation, isSystemAdmin } = user ?? {};

    eventEmitter.emit(PROFILE_EVENTS.PROFILE_POST_SUCCESS, {
      ...response,
      permissions,
      organisations,
      permissionsName,
      currentOrganisation,
      isSystemAdmin,
    });
  } catch (error) {
    eventEmitter.emit(PROFILE_EVENTS.PROFILE_POST_FAILURE);
    throw error;
  }
}

async function requestTokenClicked(eventEmitter, data) {
  const payload = Object.entries(data).reduce((a, [k, v]) => (v === null ? a : ((a[k] = v), a)), {});
  try {
    await userApi.requestToken(payload);
    eventEmitter.emit(RESET_EVENTS.RESET_SUCCESS);
  } catch (error) {
    eventEmitter.emit(RESET_EVENTS.RESET_FAILURE, error?.errors?.title);
    throw error;
  }
}

async function checkUserStatus(eventEmitter, token) {
  try {
    const payload = { jwt_token: token };
    const data = await userApi.checkUserStatus(payload);
    eventEmitter.emit(RESET_EVENTS.FETCH_USER_STATUS, data);
  } catch (error) {
    eventEmitter.emit(RESET_EVENTS.FAILURE_USER_STATUS, error?.errors?.title);
  }
}

async function checkDuplicatePhone(eventEmitter, phone) {
  try {
    const data = await userApi.checkDuplicatePhone(phone);
    eventEmitter.emit(RESET_EVENTS.FETCH_DUPLICATE_PHONE_STATUS, data);
  } catch (error) {
    eventEmitter.emit(RESET_EVENTS.FAILURE_DUPLICATE_PHONE_STATUS, error?.errors?.title);
  }
}

export {
  getMyProfile,
  forgetParentPassword,
  isSSOBtnVisible,
  loginClicked,
  otpSubmit,
  resetPasswordClicked,
  changeUserPreference,
  updateProfileClicked,
  requestTokenClicked,
  checkUserStatus,
  checkDuplicatePhone,
};
