export const ERROR_MESSAGES = {
  FAILED_TO_FETCH_LOGS: 'Unable to fetch the logs',
  FAILED_TO_FETCH_ROUTE_EVENTS: 'Unable to fetch the route events',
  FAILED_TO_FETCH_SMS_BALANCE_COUNT: 'Failed to get SMS Balance count',
  FAILED_TO_FETCH_RECIPIENTS_COUNT: 'Failed to get Recipents count',
  UANBLE_TO_SEND_MESSAGE: 'Unable to send message',
};

export const MESSAGE = {
  EMAILS_ARE_CURRENTLY: 'Emails are currently turned off for your school, please contact support to turn them on.',
  SELECT_DATES_TO_CHHOSE_ROUTES: 'Select date to choose routes scheduled for another day.',
  LOW_THRESHOLD_MESSAGE: 'Your message balance is below the threshold, please top up to send SMS messages.',
  EXCEED_MESSAGE: `Attention: Your message exceeds the size limit and will incur additional charges if sent as is. Reduce the length to avoid additional charge.`,
  MESSAGE_CONTENT: 'Message Content',
  DATE_CUSTOM_FORMAT: 'yyyy-MM-dd',
  ROLLCALL_MESSAGE_HUB: `The RollCall messaging hub is designed to make communicating with your students parents simple, from designing welcome emails, to messaging all the parents with students on certain buses.`,
  SEND_SMS_MESSAGE: 'SEND SMS MESSAGE',
  SEND_EMAIL_MESSAGE: 'SEND EMAIL MESSAGE',
  RIGHT_ROUTE_TEXT: 'Please check you have selected the right routes for your message.',
  MESSAGE_TOKEN_USED: 'Message Tokens Used',
  PARENT_TO_BE_NOTIFIED: 'Parents to be Notified via Email',
};

export const LINK = 'https://rollcallmarketplace.com.au/collections/sms-credit-top-up';

export const VALIDATION_MESSAGES = {
  PLEASE_ENTER_MESSAGE: 'Please enter the message',
};
